/* eslint-disable */

export const memberFullName = (member, mode) => {
    const isShortMode = mode && mode === 'short'

    const lastName = member.last_name || null
    const firstName = member.first_name || null
    const middleName = member.middle_name || null

    const shortFirstName = isShortMode && firstName ? `${member.first_name.charAt(0)}.` : null
    const shortMiddleName = isShortMode && middleName ? `${member.middle_name.charAt(0)}.` : null

    if(isShortMode) {
        let shortName = lastName ? `${lastName}` : ''
        shortName += shortFirstName ? ` ${shortFirstName}` : ''
        shortName += shortMiddleName ? ` ${shortMiddleName}` : ''

        return shortName.length === 0 ? 'No name' : shortName
    } else {
        let name = lastName ? `${lastName}` : ''
        name += firstName ? ` ${firstName}` : ''
        name += middleName ? ` ${middleName}` : ''

        return name.length === 0 ? 'No name' : name
    }
}
