<template>
    <v-sheet
        class="content-wrapper"
        :color="color"
        :min-height="minHeight"
    >
        <v-container
            v-if="breadcrumbsResolve || isHeader"
            class="pb-0"
            :style="containerStyles"
        >
            <div v-if="breadcrumbsResolve" class="page-breadcrumbs d-flex align-center">

                <v-btn
                    v-if="!hasTitle && comebackComputed"
                    :to="comebackComputed.to"
                    v-on="comebackComputed.on"
                    icon
                    exact
                    color="primary"
                    class="ml-n2 mr-2"
                >
                    <w-icon
                        value="PREV"
                    />
                </v-btn>
                <div class="text-truncate d-flex align-center">
                    <template
                        v-for="(brItem, index) in breadcrumbsResolve"
                    >
                        <w-icon
                            v-if="index > 0"
                            :key="`brItem-divider-${index}`"
                            small
                            value="NEXT"
                            class="mx-1"
                        />
                        <router-link
                            v-if="brItem.to"
                            :key="`brItem-link-${index}`"
                            :to="brItem.to"
                            class="text-sm text-truncate text-decoration-none"
                        >
                            {{ brItem.text }}
                        </router-link>
                        <span
                            v-else
                            :key="`brItem-link-${index}`"
                            class="text-sm text-truncate"
                        >
                            {{ brItem.text }}
                        </span>
                    </template>
                </div>
            </div>
            <v-card
                v-if="isHeader"
                flat
                color="transparent"
                :class="`d-flex align-center ${breadcrumbsResolve ? 'mt-3' : ''}`"
            >
                <v-btn
                    v-if="comebackComputed"
                    :to="comebackComputed.to"
                    v-on="comebackComputed.on"
                    icon
                    exact
                    color="primary"
                    class="ml-n2 mr-2"
                >
                    <w-icon
                        value="COM_BACK"
                    />
                </v-btn>
                <div v-if="hasTitle || hasSubtitle" class="text-truncate">
                    <h1 v-if="hasTitle" class="font-weight-semibold text-base text--primary text-truncate">
                        <slot name="title" :title="title">{{ title }}</slot>
                    </h1>
                    <div v-if="hasSubtitle" class="text-truncate caption text--secondary">
                        <slot name="subtitle" :subtitle="subtitle">{{ subtitle }}</slot>
                    </div>
                </div>
                <v-spacer class="mx-2"></v-spacer>
                <div class="d-flex align-center">
                    <slot name="actions"></slot>
                </div>
            </v-card>
        </v-container>
        <div v-if="hasDefaultSlot" class="page-content">
            <v-container
                v-bind="contentBindings"
                :style="containerStyles"
            >
                <slot></slot>
            </v-container>
        </div>
    </v-sheet>
</template>

<script>

export default {
    name: 'ContentWrapper',
    props: {
        breadcrumbs: {
            type: Array,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        color: {
            type: String,
            default: 'transparent'
        },
        comback: {
            type: [Object, String],
            default: null
        },
        comebackPrevious: {
            type: Boolean,
            default: false
        },
        maxWidth: {
            type: [ String, Number ],
            default: undefined
        },
        minHeight: {
            type: [ String, Number ],
            default: undefined
        },
        fluid: {
            type: Boolean,
            default: false
        },
        noGutters: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        comebackComputed() {
            if(this.comback) {
                return {
                    to: this.comback,
                    on: {}
                }
            } else if(this.comebackPrevious) {
                return {
                    to: undefined,
                    on: {
                        click: this.comebackPreviousCallback
                    }
                }
            }

            return null
        },
        contentBindings() {
            const classes = []

            if (this.noGutters) {
                classes.push('px-0')
            }

            return {
                class: classes
            }
        },
        containerStyles() {
            const styles = {}

            if (this.fluid) {
                styles.width = '100%'
                styles.maxWidth = '100%'
            } else if (this.maxWidth) {
                styles.maxWidth = this.maxWidth + 'px'
            }

            return styles
        },
        breadcrumbsResolve() {
            if (this.breadcrumbs) {
                return this.breadcrumbs
            }

            const routeBreadcrumbs = this.$route.meta.breadcrumbs

            if (!routeBreadcrumbs) {
                return null
            }

            const items = []

            for (let i = 0; i < routeBreadcrumbs.length; i++) {
                items.push({
                    text: this.$trans(routeBreadcrumbs[i].text),
                    to: routeBreadcrumbs[i].to
                })
            }

            return items
        },
        hasTitle() {
            return !!this.$slots.title || this.title
        },
        hasSubtitle() {
            return !!this.$slots.subtitle || this.subtitle
        },
        hasActionsSlot() {
            return !!this.$slots.actions
        },
        hasDefaultSlot() {
            return !!this.$slots.default
        },
        isHeader() {
            return this.hasTitle ||
                this.hasSubtitle ||
                this.hasActionsSlot ||
                this.comback
        }
    },
    methods: {
        comebackPreviousCallback() {
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="scss">
.content-wrapper {
    .page-title-wrapper {
        position: relative;

        .page-comback {
            height: 100%;
            position: absolute;
            left: 0;
        }
    }
}

</style>
