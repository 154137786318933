<template>
    <content-wrapper>
        <v-row>
            <v-col
                cols="12"
                lg="3"
            >
                <teacher-personal-data
                    :uuid="uuid"
                    @deleted="$router.push({ name: 'manage.school.teachers' })"
                />
            </v-col>
            <v-col
                cols="12"
                lg="9"
            >
                <v-row>
                    <v-col cols="12">
                        <teacher-departments
                            key="teacher-departments"
                            :uuid="uuid"
                        />
                    </v-col>
                    <v-col cols="12">
                        <teacher-capacity
                            key="teacher-capacities"
                            :uuid="uuid"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'

export default {
    name: 'TeacherProfile',
    components: {
        TeacherCapacity: () => import('@apps/school/components/Teacher/TeacherCapacity'),
        TeacherDepartments: () => import('@apps/school/components/Teacher/TeacherDepartments'),
        TeacherPersonalData: () => import('@apps/school/components/Teacher/TeacherPersonalData'),
        ContentWrapper
    },
    mixins: [
        hasMemberFullNameFilterMixin
    ],
    data() {
        return {
            departments: [],
            capacity: [],
            lessonTypes: [],
            isLoadingCapacity: false
        }
    },
    computed: {
        uuid() {
            return this.$route.params.uuid
        }
    }
}
</script>

<style lang="scss">

</style>
